<template>
  <div>
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      title="Valider des factures"
      @click.prevent="FacuresToConfirme"
      ><font-awesome-icon icon="check-double" class="file-medical"
    /></b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: { dataToUse: { required: true } },
  methods: {
    ...mapActions(['validateMultiFactureMensuel']),
    async FacuresToConfirme() {
      let ids = [];
      let htmlMessage =
        "<div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;' class='ModalScrol'><div> ";

      for (let i = 0; i < this.dataToUse.length; i++) {
        for (let j = 0; j < this.dataToUse[i].factures.length; j++) {
          if (
            this.dataToUse[i].factures[j] &&
            this.dataToUse[i].factures[j].check == true &&
            this.dataToUse[i].factures[j].id &&
            this.dataToUse[i].factures[j].etat != 'Annulée'
          ) {
            ids.push(this.dataToUse[i].factures[j]);
            htmlMessage =
              htmlMessage +
              '<li>' +
              this.dataToUse[i].factures[j].num +
              '</li>';
          }
        }
      }
      htmlMessage = htmlMessage + '</div></div>';

      this.$swal
        .fire({
          title: 'Êtes-vous sur de confirmer ces factures ',
          type: 'warning',
          icon: 'warning',
          locale: 'fr',
          allowOutsideClick: () => !this.$swal.isLoading(),
          stopKeydownPropagation: false,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          cancelButtonColor: '#d33',
          confirButtonColor: '#218838',
          html: htmlMessage,
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            const response = await this.validateMultiFactureMensuel(ids);
          }
        });
    }
  }
};
</script>

<style></style>
