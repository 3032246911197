<template>
  <div class="extration-bdd-frais">
    <b-button
      v-if="dataToUse.length"
      size="sm"
      class="button-export-style  ml-2"
      @click="showConfirmAlert"
      title="Transfert vers la facture libre"
    >
      <font-awesome-icon icon="file-export"
    /></b-button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    interfaceName: { required: true },
    indexsToRemove: { required: true }
  },
  data() {
    return {
      error: null,
      loading: false
    };
  },
  methods: {
    ...mapActions(['transfertFactureMensuelToLibre']),
    resetModal() {
      this.error = null;
      this.loading = false;
    },
    showConfirmAlert() {
      this.$swal
        .fire({
          background: 'rgb(245 245 252)',
          title:
            'Êtes-vous sur de transferer ces factures vers la facture libre',
          type: 'warning',
          icon: 'warning',
          locale: 'fr',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          showCloseButton: true,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          cancelButtonColor: '#d33',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            for (let i = 0; i < this.dataToUse.length; i++) {
              bodyFormData.append('ids[' + [i] + ']', this.dataToUse[i]);
            }
            const response = await this.transfertFactureMensuelToLibre({
              ids: bodyFormData,
              indexsToRemove: this.indexsToRemove
            });
            if (response) {
              this.$alert('', 'Factures transferé avec succès', 'success');
            }
          }
        });
    }
  }
};
</script>

<style scoped lang="scss">
.button-export-style {
  background-color: #e4261b;
  &:hover,
  &:focus {
    background-color: #e4261b;
  }
}
</style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-validation-statut-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
.ModalScrol::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.ModalScrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.ModalScrol::-webkit-scrollbar-thumb {
  background: #b5b5e6;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
</style>
